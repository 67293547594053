<template>
    <v-app>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card>
                <iframe :src="iframeUrl" frameborder="0" width="100%" height="1000" allowtransparency ></iframe>
            </v-card>
        </v-card>
    </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
export default {
    mounted(){
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard BI Comercial", route: "dashboard-bi-comercial" },
        ]);
        this.getiIframe();
    },
    data(){
        return{
            iframeUrl:''
        }
    },
    methods:{
        getiIframe(){
            var vm = this;
            vm.loading = true;
            // you will need to install via 'npm install jsonwebtoken' or in your package.json
            var jwt = require("jsonwebtoken");                
            this.axios({
                url: 'admin/configurations',
                params: {
                    name : "METABASE_INFO_BI_COMERCIAL"
                },
                method: 'GET'
            }).then( response => {
                console.log(response);
                if (response.data.METABASE_SITE_URL != undefined) {
                    var METABASE_SITE_URL = response.data.METABASE_SITE_URL;
                    var METABASE_SECRET_KEY = response.data.METABASE_SECRET_KEY;

                    var payload = {
                        resource: { dashboard: parseInt(response.data.METABASE_DASHBOARD_ID) },
                        params: {
                            "store_id": vm.$store.state.passport.user.default_store_id
                        },
                        exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
                    };
                    var token = jwt.sign(payload, METABASE_SECRET_KEY);
                    vm.iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false";
                }else{
                    vm.$bvToast.toast(
                        'Error al traer la configuración',
                        {
                            title: `Notificación`,
                            variant: "danger",
                            solid: true,
                            toaster: "b-toaster-bottom-center",
                        }
                    );
                }                
                vm.loading = false;
            })
        },
    }
}
</script>